<!-- eslint-disable no-magic-numbers -->
<script setup lang="ts">
    import { computed, nextTick, ref, type Ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { boolean, object } from 'zod';
    import { toTypedSchema } from '@vee-validate/zod';
    import { useForm } from 'vee-validate';
    import { useAsyncTask } from 'vue-concurrency';
    import Button from 'primevue/button';
    import Steps from 'primevue/steps';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import CheckboxField from '@/components/form-components/CheckboxField.vue';
    import { useCheckoutAction } from '@/checkout/composables/checkout';
    import DeliveryOptionStep from '@/checkout/components/sale/steps/DeliveryOptionStep.vue';
    import BillingDetailsStep from '@/checkout/components/sale/steps/BillingDetailsStep.vue';
    import SummaryStep from '@/checkout/components/sale/steps/SummaryStep.vue';
    import CheckoutCart from '@/checkout/components/CheckoutCart.vue';
    import { isMedusaAddressValid } from '@/util/addressHelpers';
    import { hasValidationError } from '@/util/hasValidationError';
    import { useCheckoutTosLink } from '@/checkout/composables/checkout-tos-link';
    import { useCheckoutCartAction } from '@/checkout/composables/checkout-cart';
    import InconsistentCartStateDialog from '@/components/cart/InconsistentCartStateDialog.vue';
    import {
        ORDER_NOTE_MAX_LENGTH,
        ORDER_REFERENCE_MAX_LENGTH,
        TransportType,
        type PaymentMethod,
    } from '@containex/portal-backend-dto';

    const { t } = useI18n();

    const cartQuery = useCartQuery();
    const cartAction = useCartAction();
    const checkoutAction = useCheckoutAction();
    const checkoutTosLink = useCheckoutTosLink();
    const checkoutCartAction = useCheckoutCartAction();

    const active = ref(0);
    const readonly = ref(true);
    const isInconsistentCartStateDialogVisible = ref(false);
    const selectedPaymentMethod: Ref<PaymentMethod | undefined> = ref(undefined);
    const orderNote: Ref<string | undefined> = ref(undefined);
    const orderReference: Ref<string | undefined> = ref(undefined);

    const isShippingOptionValid = computed(() => {
        const shippingAddressValid: boolean =
            cartQuery.cart.value?.shipping_address != null &&
            isMedusaAddressValid(cartQuery.cart.value.shipping_address);
        return (
            cartQuery.cart.value?.lineItemGroups.every(
                (lineItemGroup) => lineItemGroup.transport_type === TransportType.PickUp || shippingAddressValid
            ) ?? false
        );
    });

    const schema = object({
        acceptCheckoutTOS: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('CHECKOUT.CART.CHECKOUT_TOS_TEXT.VALIDATION_TEXT'),
            }),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [acceptCheckoutTOS] = defineField('acceptCheckoutTOS');

    const handleFinishCheckout = useAsyncTask(async (): Promise<void> => {
        const cartId = cartQuery.cart.value?.id;

        const orderId = await checkoutAction.completeCart();

        if (orderId != null) {
            await router.push({ name: Navigation.SalesCheckoutSuccess, state: { cartId, orderId } });
        }
    }).drop();

    const stepsLabels = computed(() => [
        {
            label: t('CART.STEPS.DELIVERY.STEP'),
        },
        {
            label: t('CART.STEPS.BILLING'),
        },
        {
            label: t('CART.STEPS.SUMMARY'),
        },
    ]);

    async function handleStepChange(newStepValue: number): Promise<void> {
        if (active.value === 0 && newStepValue === 1) {
            if (!isShippingOptionValid.value) {
                throw new Error('No delivery address selected');
            }
        }

        if (newStepValue === 2) {
            readonly.value = false;
        }

        if ([0, 1, 2].includes(newStepValue)) {
            active.value = newStepValue;
        }

        await nextTick(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        });
    }

    function handleSelectPaymentMethod(method: PaymentMethod): void {
        selectedPaymentMethod.value = method;
    }

    function handleUpdateOrderNote(newOrderNote: string | undefined): void {
        orderNote.value = newOrderNote;
    }

    function handleUpdateOrderReference(newOrderReference: string | undefined): void {
        orderReference.value = newOrderReference;
    }

    const onSubmit = handleSubmit(async (values) => {
        const isCheckoutCartStateValid = await checkoutCartAction.isCheckoutCartConsistentWithDatabaseCart();

        if (isCheckoutCartStateValid) {
            const paymentMethod = selectedPaymentMethod.value;
            if (paymentMethod == null) {
                const { errorToastOptions, addToast } = useGlobalToast();
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.PAYMENT_UNKNOWN.SUMMARY',
                    detail: 'ERROR.PAYMENT_UNKNOWN.DETAIL',
                });
                return;
            }
            await checkoutAction.createPaymentSessionsIfMissing();
            const updatedCart = await cartAction.updateCartBeforeCompleting(
                values.acceptCheckoutTOS,
                paymentMethod,
                orderNote.value,
                orderReference.value
            );
            if (updatedCart?.checkout_tos_accepted != null) {
                await handleFinishCheckout.perform();
            } else {
                const { errorToastOptions, addToast } = useGlobalToast();
                addToast(errorToastOptions);
            }
        } else {
            isInconsistentCartStateDialogVisible.value = true;
        }
    });

    const nextButtonTitle = computed(() => {
        if (active.value === 2) {
            return t('CART.STEPS.FINISH_CHECKOUT');
        }

        return t('CART.STEPS.CONTINUE_WITH', { name: stepsLabels.value[active.value + 1]?.label });
    });

    function handleInconsistentCartStateDialogConfirm(): void {
        isInconsistentCartStateDialogVisible.value = false;
        router.push({ name: Navigation.SalesCart });
    }
</script>

<template>
    <div class="steps-container apply-scrollbar-spacing">
        <div class="max-width-container">
            <Steps v-model:active-step="active" :model="stepsLabels" :readonly="readonly" />
        </div>
    </div>
    <div class="apply-scrollbar-spacing">
        <div class="max-width-container content-container">
            <div class="checkout-steps-container">
                <div v-if="active === 0">
                    <DeliveryOptionStep />
                </div>
                <div v-else-if="active === 1">
                    <BillingDetailsStep @select-payment-method="handleSelectPaymentMethod" />
                </div>
                <div v-else-if="active === 2">
                    <SummaryStep
                        :payment-method="selectedPaymentMethod"
                        @billing-step="active = 1"
                        @delivery-step="active = 0"
                        @order-note="handleUpdateOrderNote"
                        @order-reference="handleUpdateOrderReference"
                    />
                </div>
            </div>
            <div class="checkout-cart-container">
                <CheckoutCart :current-checkout-step="active" :is-rental="false" />
                <form class="checkout-actions-container" @submit.prevent="onSubmit">
                    <CheckboxField
                        v-if="active === 2"
                        v-model="acceptCheckoutTOS"
                        data-testid="checkout-checkbox-tos"
                        class="checkout-checkbox-tos"
                        :binary="true"
                        :invalid="hasValidationError(errors.acceptCheckoutTOS)"
                        :error-message="errors.acceptCheckoutTOS"
                    >
                        {{ t('CHECKOUT.CART.CHECKOUT_TOS_TEXT.PRE') }}
                        <a :href="checkoutTosLink" class="privacy-policy-link" target="_blank">
                            <span class="pi pi-external-link checkout-tos-external-link-icon" />
                            {{ t('CHECKOUT.CART.CHECKOUT_TOS_TEXT.LINK_TEXT_SALES') }}
                        </a>
                        {{ t('CHECKOUT.CART.CHECKOUT_TOS_TEXT.POST') }}
                    </CheckboxField>
                    <div class="checkout-actions-button-container">
                        <Button
                            v-if="active === 2"
                            :label="nextButtonTitle"
                            :disabled="
                                !isShippingOptionValid ||
                                (orderReference != null && orderReference.length > ORDER_REFERENCE_MAX_LENGTH) ||
                                (orderNote != null && orderNote.length > ORDER_NOTE_MAX_LENGTH)
                            "
                            icon-pos="right"
                            class="full-width text-base-semibold-line-height-auto"
                            type="submit"
                        />
                        <Button
                            v-else
                            :label="nextButtonTitle"
                            :disabled="!isShippingOptionValid"
                            icon-pos="right"
                            class="full-width text-base-semibold-line-height-auto"
                            @click="handleStepChange(active + 1)"
                        />
                        <Button
                            v-if="active != 0"
                            class="step-back-button full-width text-base-semibold-line-height-auto"
                            :label="t('CART.STEPS.BACK')"
                            link
                            @click="handleStepChange(active - 1)"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
    <InconsistentCartStateDialog
        :is-visible="isInconsistentCartStateDialogVisible"
        @close="isInconsistentCartStateDialogVisible = false"
        @confirm="handleInconsistentCartStateDialogConfirm"
    />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkout-tos-external-link-icon {
        font-size: main.$font-size-xs;
    }

    .privacy-policy-link {
        color: main.$ctx-primary-color;
        text-decoration: none;
    }

    .checkout-actions-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: main.$spacing-5;
    }

    .checkout-checkbox-tos {
        width: 100%;
        padding: main.$spacing-5;
        background: main.$vt-c-white;
        border-radius: 4px;
    }

    .checkout-actions-button-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: main.$spacing-3;
        padding: 0 main.$spacing-5;
    }

    .checkout-cart-container {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .checkout-steps-container {
        width: 100%;
    }

    .steps-container {
        display: flex;
        align-items: center;
        padding: main.$spacing-5 0;
        background: main.$vt-c-white;

        nav {
            width: 100%;
        }
    }

    .step-back-button:hover {
        background-color: main.$button-blue-gray-half-transparent-hover-background-color;
    }

    .content-container {
        padding-top: main.$spacing-5;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        gap: main.$spacing-5;
    }

    @include main.for-breakpoint-md {
        .content-container {
            padding-top: main.$spacing-6;
            padding-left: main.$spacing-6;
            padding-right: main.$spacing-6;
            flex-direction: row;
            gap: main.$spacing-6;
        }
    }

    @include main.for-breakpoint-xl {
        .content-container {
            flex-wrap: nowrap;
        }

        .checkout-cart-container {
            width: 384px;
        }
    }

    .apply-scrollbar-spacing {
        @include main.apply-scrollbar-spacing;
    }

    :deep(.p-steps-number) {
        background: main.$color-background-lightblue;
        color: main.$ctx-primary-color;
        border-color: main.$color-border-lightgray;
    }
</style>
